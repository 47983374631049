import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import { decodeSessionDetails } from "../../Services/CommonServices";

export default function Header() {

    const { pathname } = useLocation();
    const history = useHistory();
    const hamburgerChildElement = useRef<HTMLDivElement>(null);
    const [tab, setTab] = useState<string>("");
    const [logoutpopup, setlogoutpopup] = useState<boolean>(false)
    let userName: string = '';
    let role: string = '';
    let userLocation: string;
    let c_local: any = sessionStorage.getItem("c_at");
    let cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

    let [objClient, setObjClient] = useState<any>();
    let [objAdmin, setObjAdmin] = useState<any>();
  
    let a_local: any = sessionStorage.getItem("a_at");
    let as_secretKey: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;


    const decodeSession = async () => {
        try {
            debugger;
          let response: any = await decodeSessionDetails();
          console.log("response from server :", response);
          if(response?.clientDetails){
          if (c_local != undefined) {
            setObjClient(response.clientDetails)
            userName = objClient.Name;
            role = objClient.Role;
          }
        }
        if(response?.adminDetails){
          if (a_local != undefined) {
            setObjAdmin(response.adminDetails);
            if (objClient == undefined) {
                userName = objAdmin.Name;
                role = objAdmin.Role;
            }
          }
        }
        }
        catch (error) {
          console.log(error);
        }
      }

    // if (c_local != undefined) {
    //     let c_byteValue = objCryptoJS.AES.decrypt(c_local, cs_secretKey);
    //     var c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
    //     objClient = jwt(c_decryptedValue);
    //     userName = objClient.Name;
    //     role = objClient.Role;
    // }

    // if (a_local != undefined) {
    //     let a_byteValue = objCryptoJS.AES.decrypt(a_local, as_secretKey);
    //     var a_decryptedValue = a_byteValue.toString(objCryptoJS.enc.Utf8);
    //     objAdmin = jwt(a_decryptedValue);
    //     if (objClient == undefined) {
    //         userName = objAdmin.Name;
    //         role = objAdmin.Role;
    //     }
    // }

    let taskHighlight: string[] = ['task', 'configurations', 'feedbacks', 'viewmigration', 'migrationreport', 'usermapping', 'mappedusers', 'connectors', 'migration', 'selectapps', 'assessmentconfigurations', 'assessment', 'assessmentsummary', 'viewassessment', 'inprogress', 'customcreationandmapping']

    useEffect(() => {
        debugger
        decodeSession();
        if (pathname.toLowerCase().includes("dashboard")) {
            userLocation = "/dashboard";
        }
        else if (pathname.toLowerCase().includes("/admin/licenserenewal")) {
            userLocation = "/dashboard";
        }
        else if (taskHighlight.some(item => pathname.toLowerCase().includes(item))) {
            userLocation = '/task';
        }
        else if (pathname.toLowerCase().includes('manageclient') || pathname.toLowerCase().includes('newclient') || pathname.toLowerCase().includes('updateclient')) {
            userLocation = 'admin';
        }
        else if (taskHighlight.some(item => pathname.toLowerCase().includes(item)) || pathname.toLowerCase().includes('/admin/feedback')) {
            userLocation = '/feedback';
            ////console.log(userLocation, "existing location :");
        }
        else if (pathname.includes("/admin/configuration")) {
            userLocation = "/admin/configuration"
        }
        if (pathname.toString().toLowerCase().includes("admin")) {
            document.title = `AVAMIGRATRON - Admin`
        }
        else if (pathname.toString().toLowerCase().includes("view")) {
            const path = pathname.toString().split('/')
            const pathSplit1 = path[1].toString().substring(0, 4);
            const pathSplit2 = path[1].toString().substring(4);
            document.title = `AVAMIGRATRON - ${pathSplit1.charAt(0).toUpperCase() + pathSplit1.slice(1) + ' ' + pathSplit2.charAt(0).toUpperCase() + pathSplit2.slice(1)}`
            console.log(pathSplit1[0].charAt(0).toUpperCase() + pathSplit1[0].slice(1));
            // console.log(pathSplit1[1].charAt(0).toUpperCase() + pathSplit1[1].slice(1));            
        }
        else if (pathname.toString().toLowerCase().includes("report")) {
            const path = pathname.toString().split('/')
            const pathSplit1 = path[1].toString().substring(0, 9);
            const pathSplit2 = path[1].toString().substring(9);
            document.title = `AVAMIGRATRON - ${pathSplit1.charAt(0).toUpperCase() + pathSplit1.slice(1) + ' ' + pathSplit2.charAt(0).toUpperCase() + pathSplit2.slice(1)}`
        }
        else if (pathname.toString().toLowerCase().includes("summary")) {
            const path = pathname.toString().split('/')
            const pathSplit1 = path[1].toString().substring(0, 10);
            const pathSplit2 = path[1].toString().substring(10);
            document.title = `AVAMIGRATRON - ${pathSplit1.charAt(0).toUpperCase() + pathSplit1.slice(1) + ' ' + pathSplit2.charAt(0).toUpperCase() + pathSplit2.slice(1)}`
        }
        else {
            const path = pathname.toString().split('/')
            document.title = `AVAMIGRATRON - ${path[1].charAt(0).toUpperCase() + path[1].slice(1)}`
        }
        ////console.log(userLocation, "current location : ");
        setTab(userLocation);
    }, [pathname]);

    const handleLogOut = async () => {
        setlogoutpopup(true)
    }

    function renderLogOutPopUp() {
        return (
            <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: logoutpopup ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">
                                Alert
                            </h5>
                            <p className="primary-data">Are you sure you want to Logout?</p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                onClick={() => {
                                    setlogoutpopup(false);
                                    if (objAdmin != undefined) {
                                        history.push('/admin/login');
                                        sessionStorage.clear();
                                    }
                                    else {
                                        history.push('/login');
                                        sessionStorage.clear();
                                    }


                                }}
                                className="btn primary-btn font-14 px-4 font-semibold"
                                data-bs-dismiss="modal"
                            >
                                OK
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setlogoutpopup(false);
                                }}
                                className="btn primary-btn font-14 px-4 font-semibold"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const handleLinkClick = (type: string) => {
        ////console.log(type, "test")
        debugger
        const path = window.location.pathname.toString();
        (type == "/Admin/ManageClients" || path == "/Admin/ManageClients/UpdateClient" || path == "/Admin/ManageClients/NewClient") ? setTab("admin") : setTab(type);
        if (hamburgerChildElement.current) {
            hamburgerChildElement.current.className =
                hamburgerChildElement.current.className.includes("show")
                    ? hamburgerChildElement.current.className.replace("show", "") + " collapsed "
                    : hamburgerChildElement.current.className;
        }
        if (objAdmin && objClient == undefined && !(path.toLowerCase().includes('admin'))) {
            history.push(`/Admin/${type}`);
        }
        else {
            history.push(type);
        }
    };

    // Conditional rendering based on URL
    if (!pathname.toLowerCase().includes('/registration') &&
        !pathname.toLowerCase().includes('/login') &&
        !pathname.toLowerCase().includes('/admin/login') &&
        !pathname.toLowerCase().includes('/forgotpassword')) {
        return (
            <>
                <nav className="navbar navbar-expand-md shadow-sm topnav-z-index navbar-light sticky-top bg-white py-3" style={{ zIndex: '1050' }}>
                    <div className="container-fluid" style={{ cursor: 'pointer' }}>
                        <a
                            className="navbar-brand ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                debugger
                                setTab("/dashboard");
                                if (objAdmin != undefined && objClient != undefined) {
                                    handleLinkClick("/Dashboard")
                                }
                                else if (objAdmin != undefined) {
                                    handleLinkClick("/Admin/Dashboard")
                                }
                                else if (objClient != undefined) {
                                    handleLinkClick("/Dashboard")
                                }
                                else {
                                    window.open('https://www.avamigratron.com/Product', '_blank');
                                }
                            }}
                        >
                            <img src="/images/brand-logo.svg" alt="brand-logo" style={{ cursor: 'pointer' }} />
                        </a>
                        <button
                            className="navbar-toggler collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarsExample04"
                            aria-controls="navbarsExample04"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        {(objClient || objAdmin) ?
                            <div
                                ref={hamburgerChildElement}
                                className="navbar-collapse collapse"
                                id="navbarsExample04"
                            >
                                <ul className="navbar-nav me-auto mb-2 mb-md-0 gap-md-4 ms-md-4 mt-3 mt-md-0 ms-3">
                                    {/*Dashboard*/}
                                    <li className="nav-item">
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                if (objClient == undefined) {
                                                    handleLinkClick("/Admin/Dashboard")
                                                }
                                                else {
                                                    handleLinkClick("/Dashboard")
                                                }
                                            }}
                                            className={`nav-link font-14 top-nav-link cursor-pointer ${tab?.toLowerCase() === "/dashboard" ? "active" : ""}`}
                                        >
                                            Dashboard
                                        </a>

                                    </li>
                                    {(objAdmin && objClient == undefined) ? (
                                        <>

                                            {/*Coniguration*/}
                                            <li className="nav-item">
                                                <a
                                                    onClick={() => handleLinkClick("/Admin/Configurations")}
                                                    className={`nav-link font-14 top-nav-link cursor-pointer${tab?.toLowerCase() === "/admin/configurations" ? "active" : ""}
                                                        `}
                                                >
                                                    Configuration
                                                </a>
                                            </li>
                                            {/*Admin*/}
                                            <li className="nav-item">
                                                <a
                                                    onClick={() => handleLinkClick("/Admin/ManageClients")}
                                                    className={`nav-link font-14 top-nav-link cursor-pointer ${tab?.toLowerCase() === "admin" ? "active" : ""
                                                        }`}
                                                >
                                                    Admin
                                                </a>
                                            </li>
                                            {/*Feedback*/}
                                            <li className="nav-item">
                                                <a
                                                    onClick={() => handleLinkClick("/Admin/Feedback")}
                                                    className={`nav-link font-14 top-nav-link cursor-pointer ${tab?.toLowerCase() === "/feedback" ? "active" : ""}
                                                        `}
                                                >
                                                    Feedbacks
                                                </a>
                                            </li>
                                        </>)
                                        : null}
                                    {objClient ? (
                                        <>
                                            {/*Task*/}
                                            < li className="nav-item">
                                                <a
                                                    onClick={() => handleLinkClick("/Task")}
                                                    className={`nav-link font-14 top-nav-link cursor-pointer ${tab?.toLowerCase() === "/task" ? "active" : ""
                                                        }`}
                                                >
                                                    Task
                                                </a>
                                            </li>
                                        </>
                                    ) : null}
                                </ul>
                                <span className="border-bottom d-block w-100 d-block d-md-none mb-3" />
                                <ul className="list-unstyled mb-0 d-md-flex gap-4 d-grid ms-3 align-items-center">
                                    {/*Contact Us*/}
                                    <li>
                                        <a
                                            onClick={() =>
                                                (objClient == undefined) ? handleLinkClick("/Admin/Contactus")
                                                    : handleLinkClick("/Contactus")}
                                            className="nav-link p-0 cursor-pointer"
                                        >
                                            <img src="/images/headset-icon.svg" alt="headset-icon" />{" "}
                                            <span className="text-nowrap font-semibold font-14 text-dark ms-2 d-inline-block d-md-none">
                                                Contact Us
                                            </span>
                                        </a>
                                    </li>
                                    {/*Help & Doc*/}
                                    <li>
                                        <a
                                            onClick={() => {
                                                (objClient == undefined) ? handleLinkClick("/Admin/HelpandDocumentation")
                                                    : handleLinkClick("/HelpandDocumentation")
                                            }}
                                            className="nav-link p-0 cursor-pointer"
                                        >
                                            <img src="/images/queries-icon.svg" alt="headset-icon" />{" "}
                                            <span className="text-nowrap font-semibold font-14 text-dark ms-2 d-inline-block d-md-none">
                                                Help &amp; Documentation
                                            </span>
                                        </a>
                                    </li>
                                    {/*Raise a Feedback*/}
                                    {!role.toLowerCase().includes('admin') &&
                                        <li className="d-block d-md-none">
                                            <a
                                                onClick={() =>
                                                    handleLinkClick("/feedback")}
                                                className="nav-link p-0 cursor-pointer"
                                            >
                                                <img src="/images/feedback-icon.svg" alt="headset-icon" />{" "}
                                                <span className="text-nowrap font-semibold font-14 text-dark ms-2 d-inline-block d-md-none">
                                                    Raise Feedback
                                                </span>
                                            </a>
                                        </li>
                                    }
                                    {/*Profile Details*/}
                                    {/*User Name*/}
                                    <li className="d-flex align-items-center justify-content-between d-md-none">
                                        
                                            <span className="d-flex align-items-center gap-2 pt-2">
                                                <span className="d-flex align-items-center justify-content-center font-14 font-semibold profile-legend-name success" onClick={() => {
                                                        objClient == undefined ?
                                                            handleLinkClick("/Admin/Profile") : handleLinkClick("/Profile")
                                                    }}>
                                                    {userName[0]}</span>
                                                <span className="d-grid"><span className="text-nowrap font-semibold font-14">{userName}</span>
                                                <span className="text-nowrap font-regular font-10"> {role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()} </span>
                                                </span>
                                            </span>
                                            
                                        <img
                                            src="/images/logout-icon.svg"
                                            alt="logout-icon"
                                            className="me-3"
                                            onClick={() => (handleLogOut())}
                                        />
                                    </li>
                                    {/*Profile Info*/}
                                    <li>
                                        <span className="dropdown d-none d-md-block">
                                            <span className="nav-link p-0" data-bs-toggle="dropdown">
                                                <span className="d-flex align-items-center justify-content-center font-14 font-semibold profile-legend-name success">
                                                    {userName[0]}
                                                </span>
                                            </span>
                                            <span className="dropdown-menu dropdown-menu-end border-0 profile-pic-dropdown mt-2 ">
                                                <span className="d-flex align-items-center gap-2 pt-2 px-3">
                                                    <span className="d-flex align-items-center justify-content-center font-14 font-semibold profile-legend-name success">
                                                        {userName[0]}
                                                    </span>
                                                    <span className="d-grid">
                                                        <span className="text-nowrap font-semibold font-14">
                                                            {userName}
                                                        </span>
                                                        <span className="text-nowrap font-regular font-10">
                                                            {role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()}                                            </span>
                                                    </span>
                                                </span>
                                                <ul className="list-unstyled theme-drop-list font-14 font-regular mt-2">
                                                    <li
                                                        onClick={() => {
                                                            objClient == undefined ?
                                                                handleLinkClick("/Admin/Profile") : handleLinkClick("/Profile")
                                                        }}
                                                        className="py-1 px-3 cursor-pointer"
                                                    >
                                                        Profile Update
                                                    </li>

                                                </ul>

                                                {!role.toLowerCase().includes('admin') &&
                                                    <ul className="list-unstyled theme-drop-list font-14 font-regular mt-2">
                                                        <li
                                                            onClick={() => handleLinkClick("/feedback")}
                                                            className="py-1 px-3 cursor-pointer"
                                                        >
                                                            Raise Feedback
                                                        </li>

                                                    </ul>
                                                }

                                                <span className="font-14 font-semibold text-center logout px-2 pt-2 w-100 d-inline-block cursor-pointer">
                                                    <span className="d-flex align-items-center justify-content-center p-2 gap-1" onClick={() => (handleLogOut())}>
                                                        <img src="/images/logout-icon.svg"
                                                            alt="logout-icon"

                                                        />
                                                        Logout
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            : null}
                        {logoutpopup && renderLogOutPopUp()}
                    </div>
                </nav>
            </>

        )
    } else {
        return null;
    }
}
